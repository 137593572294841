/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-transfer {
  font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}
.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}
.ant-transfer-list {
  border: 1px solid #d9d9d9;
  display: inline-block;
  border-radius: 4px;
  vertical-align: middle;
  position: relative;
  width: 180px;
  height: 200px;
  padding-top: 34px;
}
.ant-transfer-list-with-footer {
  padding-bottom: 34px;
}
.ant-transfer-list-search {
  padding: 0 8px;
}
.ant-transfer-list-search-action {
  color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  width: 28px;
  line-height: 32px;
  text-align: center;
}
.ant-transfer-list-search-action .anticon {
  transition: all 0.3s;
  color: rgba(0, 0, 0, 0.25);
}
.ant-transfer-list-search-action .anticon:hover {
  color: rgba(0, 0, 0, 0.45);
}
span.ant-transfer-list-search-action {
  pointer-events: none;
}
.ant-transfer-list-header {
  padding: 6px 12px;
  border-radius: 4px 4px 0 0;
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid #e8e8e8;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ant-transfer-list-header-title {
  position: absolute;
  right: 12px;
}
.ant-transfer-list-body {
  font-size: 14px;
  position: relative;
  height: 100%;
}
.ant-transfer-list-body-search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  padding: 4px;
  width: 100%;
}
.ant-transfer-list-body-with-search {
  padding-top: 40px;
}
.ant-transfer-list-content {
  height: 100%;
  overflow: auto;
  list-style: none;
  padding: 0;
  margin: 0;
}
.ant-transfer-list-content > .LazyLoad {
  animation: transferHighlightIn 1s;
}
.ant-transfer-list-content-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 6px 12px;
  min-height: 32px;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > span {
  padding-right: 0;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  cursor: pointer;
  background-color: #e6f7ff;
}
.ant-transfer-list-content-item-disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-transfer-list-body-not-found {
  padding-top: 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -10px;
}
.ant-transfer-list-footer {
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.ant-transfer-operation {
  display: inline-block;
  overflow: hidden;
  margin: 0 8px;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
@keyframes transferHighlightIn {
  0% {
    background: #bae7ff;
  }
  100% {
    background: transparent;
  }
}
