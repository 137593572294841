/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-input-number {
  font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-variant: tabular-nums;
  box-sizing: border-box;
  list-style: none;
  position: relative;
  padding: 4px 11px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  margin: 0;
  padding: 0;
  display: inline-block;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 90px;
}
.ant-input-number::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input-number:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-input-number:focus {
  border-color: #40a9ff;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px !important;
}
.ant-input-number-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-input-number-disabled:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
  min-height: 32px;
}
.ant-input-number-lg {
  padding: 6px 11px;
  height: 40px;
  font-size: 16px;
}
.ant-input-number-sm {
  padding: 1px 7px;
  height: 24px;
}
.ant-input-number-handler {
  text-align: center;
  line-height: 0;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  position: relative;
  transition: all 0.1s linear;
  display: block;
  width: 100%;
  font-weight: bold;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #40a9ff;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  font-style: normal;
  vertical-align: -0.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 12px;
  user-select: none;
  position: absolute;
  width: 12px;
  height: 12px;
  transition: all 0.1s linear;
  display: inline-block;
  font-size: 12px;
  font-size: 7px \9;
  transform: scale(0.58333333) rotate(0deg);
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.ant-input-number-handler-up-inner:before,
.ant-input-number-handler-down-inner:before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
:root .ant-input-number-handler-up-inner,
:root .ant-input-number-handler-down-inner {
  font-size: 12px;
}
.ant-input-number:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-input-number-focused {
  border-color: #40a9ff;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px !important;
}
.ant-input-number-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-input-number-disabled:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  text-align: left;
  outline: 0;
  -moz-appearance: textfield;
  height: 30px;
  transition: all 0.3s linear;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  padding: 0 11px;
}
.ant-input-number-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input-number-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.ant-input-number-lg input {
  height: 38px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  border-left: 1px solid #d9d9d9;
  width: 22px;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-radius: 0 4px 4px 0;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  border-top: 1px solid #d9d9d9;
  top: 0;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  margin-top: -6px;
  text-align: center;
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}
